* {
  margin: 0;
  box-sizing: border-box;
}

body {
  --black: #000000;
  --ash-black: #222;
  --white: #f9f0df;
  --sky: #00ccff;
  --darkgreen: #1d4036;
  --lightgreen: #115945;
  --blue: #1300ff;
  --dusk: #6600ff;
  --purple: #9900ff;
  --pink: #ff0066;
  --red: #fe0222;
  --orange: #fd7702;
  --brown: #a45826;
  --yellow: #dd9331;

  --background: var(--white);
  --accent: var(--white);
  padding: 0;
  margin: 0;
}

.header {
  position: sticky;
  align-self: center;
  top: 0;
  height: 60px;
  transition:
    top 0.5s,
    margin 0.5s,
    background 0.5s;
  background: var(--background) transparent;
  margin: 0;
}

.header.hide {
  top: -60px;
}

.header.sticky.show {
  top: 2vh;
}

.header.sticky {
  margin: 2vh 5vw 0 5vw;
  border-radius: 15px;
  background: var(--background);
}

.header::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-radius: 15px;
  transition: opacity 0.3s ease-in-out;
}

.header.sticky::after {
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  opacity: 1;
}
.header.open {
  top: 0;
  background: var(--background);
  margin: 0 5vw 0 5vw;
  border-radius: 15px;
}

.sticky-social {
  position: sticky;
  position: -webkit-sticky;
  top: 70px; /* offset from top */
  right: 15px;
  display: flex;
  flex-direction: column;

  padding: 10px;
  border-radius: 10px;
}

.overlay {
  padding-top:2em ;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  z-index: 99;
  transition: opacity 0.3s ease-in-out;
}

.chakra-container .slick-track {
  display: flex;
  align-items: center;
  z-index: 99;
}

.chakra-container .slick-list {
  z-index: 9;
}

.carouseljourney {
  height: 100%;
}

.slick-dots li button:before {
  color: #fff;
}

.slick-dots li.slick-active button:before {
  color: #fff;
}

html {
  scroll-snap-type: y proximity;
}

section {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  scroll-snap-align: center;
  perspective: 500px;
  background-color: var(--darkgreen);
}

section > div {
  width: 500px;
  height: 400px;
  position: relative;
  max-height: 90vh;
  margin: 20px;
  overflow: hidden;
  color: var(--white);
}

h3 {
  font-weight: bold;
  color: var(--yellow);
}

.wrapper {
  position: relative;
}

.image-wrapper {
  position: absolute;
  height: 100%;
}

.image {
  position: sticky;
  top: 0;
  left: 30px;
  width: 30vw;
}

.first,
.second,
.third {
  height: 70vh;
}

.first {
  background-color: lightblue;
}
.second {
  background-color: lightpink;
}
.third {
  background-color: lightseagreen;
}

#mc_embed_signup_scroll input,
#mc_embed_signup_scroll textarea {
  color: #000000;
  border: solid #cccccc 1px;
  padding: 10px;
  border-radius: 15px;
  width: 100%;
}

#mc_embed_signup_scroll input:focus-visible,
#mc_embed_signup_scroll textarea:focus-visible {
  outline: 2px solid #DD9331;
}


#mc_embed_signup_scroll textarea {
  resize: vertical;
  height: 200px;
}

#mc_embed_signup_scroll input:active {
  border-color: #DD9331;
}

#mc_embed_signup_scroll input:focus-visible {
  border-color: #DD9331;
}

form .button {
  color: #1d4036;
  background-color: #dd9331;
  font-weight: bold;
  padding: 10px;
  border-radius: 15px;
  width: 100%;
  margin-top: 10px;
  cursor: pointer;
}

form .button:hover {
  background-color: #1d4036;
  color: #ffffff;
}
