.hero-background {
  position: absolute;
  z-index: 1;
  opacity: 0.2;
  aspect-ratio: 1;
  background-position: center;
  background-size: contain;
  width: var(--chakra-sizes-914);
  height: var(--chakra-sizes-914);
  top: -40em;
  left: calc(var(--chakra-space-base) * (-5));
  border-radius: full;
  backdrop-filter: multiply(0.2);
  background-image: url(../assets/images/grain-bg-sun.png);
  background-repeat: no-repeat;
}

.hero-title {
  color: #DD9331;
  font-size: 43px;
  line-height: 1;
  font-weight: bold;
}

.highlight {
  color: #1D4036;
}

.hero-subtitle {
  font-size: 16px;
  line-height: 1.375;
  font-weight: 400;
  color: #1D4036;
}

.bold-text {
  font-weight: 600;
}
