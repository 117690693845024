.preloader {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f9f0df;
  overflow: hidden !important;
  background-color: #eedcbb;
  background-position: center;

  .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 280px;
    overflow: hidden;
    opacity: 1;
    color: #f9f0df;

    @media (max-width: 767px) {
      width: 240px;
    }
  }
}
