html,
body {
  padding: 0;
  margin: 0;
  font-family: Roboto, sans-serif;
}

body {
  background: #f9f0df !important;
}

a {
  color: inherit;
  text-decoration: none;
}

p {
  font-size: 16px;
}

* {
  box-sizing: border-box;
}

h1,
h2 {
  color: inherit;
}

h2 {
  font-size: 25px;
}

h3 {
  font-size: 25px;
  font-weight: bold;
  color: var(--brown);
}
